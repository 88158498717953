const messages = {
  'p.login.welcome': 'Quality Management System',
  'p.login.or': 'Or',
  'p.errorpage.403': 'Access Denied',
  'p.errorpage.404': 'Page is not found',
  'p.errorpage.home': 'Return to home page',
  'p.table.action': 'Operation',
  'p.table.lastupdatedby': 'Update Person',
  'p.table.lastupdatedtime': 'Update Date',
  'p.table.remark': 'Remark',
  'p.document.document_sn': 'Document No.',
  'p.document.version_code': 'Version',
  'p.documenttag.tagcode': 'Tags Code',
  'p.documenttag.tagname': 'Tags Name',
  'p.documenttag.tagtype': 'Tags Type',
  'p.documenttag.relatedtagcode': 'Related Tags',
  'p.documenttag.sequence': 'Sequence',
  'p.documenttag.nodeid': 'Document Hierarchy',
  'p.documenttag.rules': 'Rules',
  'p.documenttag.tagtype.0': 'Define',
  'p.documenttag.tagtype.1': 'Text',
  'p.documenttag.tagtype.2': 'Number',
  'p.documenttag.tagtype.3': 'Date',
  'p.documenttag.tagtype.4': 'Dropdown(single)',
  'p.documenttag.tagtype.5': 'Dropdown(multiple)',
  'p.documenttag.tagtype.6': 'Hierarchy(single)',
  'p.documenttag.tagtype.7': 'Tree(multiple)',
  'p.documenttag.getlisterror': 'Fail to get tags',
  'p.treenode.parentname': 'Node location',
  'p.treenode.nodecode': 'Node Code',
  'p.treenode.name.en-US': 'English Name',
  'p.treenode.name.zh-CN': 'Chinese Name',
  'p.treenode.dataclassification': 'Data Classification',
  'p.treenode.isDefault': 'IsDefault',
  'p.treenode.sequenceno': 'Sequence',
  'p.treenode.remark': 'Remark',
  'p.docdetail.pagetitle': 'Details',
  'p.docdetail.docinfo': 'Document Infor',
  'p.docdetail.sn': 'Document No.',
  'p.docdetail.title': 'Document Name',
  'p.docdetail.version': 'Version',
  'p.docdetail.dataclassification': 'Data Classification',
  'p.docdetail.file': 'Document',
  'p.docdetail.attachments': 'Attachments',
  'p.docdetail.editfile': 'Upgrade Document',
  'p.docdetail.edittag': 'Edit Tags',
  'p.docdetail.documentleaveamessage': 'Document Comments',
  'p.docdetail.status': 'Status',
  'p.docdetail.document_structure_tree': 'Document Tree',
  'p.docdetail.select_document_structure_tree': 'Please select document tree',
  'p.docdetail.notify_personnel': 'Notify User',
  'p.docdetail.notify_department': 'Notify Department',
  'p.docdetail.notify_personnel.title': 'Please select notify user',
  'p.docdetail.department': 'Department',
  'p.docdetail.user': 'User',
  'p.docdetail.standard': 'Department Standard',
  'p.docdetail.mainfile': 'Main Document',
  'p.docdetail.annex': 'Attachment',
  'p.docdetail.structure': 'Document Structure',
  'p.docdetail.type': 'Document Type',
  'p.docdetail.author': 'Author',
  'p.docdetail.key.word': 'Keyword',
  'p.docdetail.limitaccesslevel':
    'The data classfications should be the same for one document.',
  'p.document.createby': 'Creator',
  'p.document.reviewby': 'Checker',
  'p.document.approvedby': 'Approver',
  'p.approvallog.action': 'by {user}{action}',
  'p.approvallog.submit': 'Submit',
  'p.approvallog.approve': 'Approve',
  'p.approvallog.reject': 'Reject',
  'p.approvallog.removeapprover': 'Remove',
  'p.approvallog.addapprover': 'Add',
  'p.approvallog.pending': 'in approval',
  'p.approvallog.unapproved': 'To Approve',
  'p.doc.moveto': 'Move to',
  'p.doc.addto': 'Add to',
  'p.doc.viewhistory': 'View History Versions',
  'p.doc.history': 'History Versions',
  'p.doc.approvers': 'Approvers Information',
  'p.doc.approvalhistory': 'Approval History',
  'p.doc.approvalcomment': 'Approval Comments',
  'p.doc.document_no': 'Document No.',
  'p.doctable.shareby': 'Shared User',
  'p.doctable.sharewith': 'Shared with',
  'p.doctable.deletedocument': 'Delete Document',
  'p.doctable.reason': 'Reason',
  'p.doctable.emailnotifypersonnel': 'Notity User',

  'p.updateapprover.listpage.title': 'Adjusting Approvers',
  'p.updateapprover.detailpage.title': 'Modify Approver',

  'p.document.create.basicinformation': 'Document Basic Information',
  'p.common.document': 'Main Document',
  'p.common.relateddocument': 'Attachment',
  'p.common.action': 'Operation',
  'p.common.updated': 'Updated',
  'p.doctable.status': 'Status',
  'p.doctable.status.draft': 'In Draft',
  'p.doctable.status.pendingreview': 'To Check',
  'p.doctable.pendingratify': 'To Approve',
  'p.doctable.status.pendingrelease': 'To Release',
  'p.doctable.status.approved': 'Released',
  'p.doctable.status.rejected': 'Rejected',
  'p.doctable.status.approvedby1': 'Checker 1',
  'p.doctable.status.approvedby2': 'Checker 2',
  'p.doctable.status.approvedby3': 'Checker 3',
  'p.doctable.status.ratifyby1': 'Approver 1',
  'p.doctable.status.ratifyby2': 'Approver 2',
  'p.doctable.status.ratifyby3': 'Approver 3',
  'p.doctable.approved': 'Approved',
  'p.doctable.department': 'Department',
  'p.doctable.isostandard': 'ISO Standard',
  'p.doctable.applicantname': 'Creator',
  'p.doctable.publishtime': 'Released Time',
  'p.doctable.publishstarttime': 'Start Released Time',
  'p.doctable.publishendtime': 'End Released Time',
  'p.doctable.applicationTime': 'Submitted Time',
  'p.doctable.applicationstarttime': 'Start Submitted Time',
  'p.doctable.applicationendtime': 'End Submitted Time',
  'p.doctable.createdtime': 'Creation Time',
  'p.doctable.document_no': 'Document No.',
  'p.doctable.document_name': 'Chinese Name/English Name',
  'p.doctable.document_condition': 'Document No. or Document Name',
  'p.doctable.fulltextsearch': 'Full Search',
  'p.doctable.waitingprocessing': 'ToDoTask',
  'p.doctable.Processed': 'ProcessedList',
  'p.approval.docpagetitle': 'Approve Document',
  'p.approval.pending': 'To Approve',
  'p.approval.approved': 'Approved',
  'p.approval.rejected': 'Rejected',
  'p.emailtemplate.classify': 'Email Classification',
  'p.emailtemplate.name': 'Email Template Name',
  'p.emailtemplate.code': 'Email Template Code',
  'p.emailtemplate.subjecttemplate': 'Email Tittle',
  'p.emailtemplate.bodytemplate': 'Email Content',
  'p.emailtemplate.createtitle': 'New Email Template',
  'p.emailtemplate.edittitle': 'Edit Email Template',
  'p.MessageList.name': 'Comments List',
  'p.MessageList.givemeleaveamessage': 'Comments to me',
  'p.MessageList.message': 'Comments',
  'p.MessageList.leaveMessageUser': 'Comments User',
  'p.doctable.favorite': 'Favorites',
  'p.doctable.favoritecategory': 'Favorites Category',
  'p.doctable.favoritecategoryname': 'Favorite Name',
  'p.doctable.view': 'View',
  'p.doctable.designatedpersonnel': 'Specified Users',
  'p.doctable.designateddepartment': 'Specified Department',
  'p.doctable.sharetime': 'SharedTime',
  'p.doctable.sharestarttime': 'SharedTimeStart',
  'p.doctable.shareendtime': 'SharedTimeEnd',
  'p.doctable.systemfavorites': 'SystemFavorites',
  'p.doctable.removedremark': 'DeleteReason',
  'p.announcement.title': 'Title',
  'p.announcement.content': 'Content',
  'p.announcement.detail.content': 'Content',
  'p.announcement.iseffect': 'Is Valid',
  'p.announcement.iseffect.yes': 'Yes',
  'p.announcement.iseffect.no': 'No',
  'p.doctable.approver': 'Approver',
  'p.doctable.reviewer': 'Checker',
  'p.doctable.qa': 'QA',
  'p.doctable.notset': 'NotSet',
  'p.doctable.loading': 'Loading',
  'p.doctable.edit.range': '[{value}] region is in editing',
  'p.doctable.range.remark': 'Operation Instruction:',
  'p.doctable.range.remark1': '1. Click Upload button to upload picture.',
  'p.doctable.range.remark2':
    '2. Press on left key of mouse to draw section in the picture.',
  'p.doctable.range.remark3':
    '3. Double click on the drawed section to set condition.',
  'p.doctable.range.remark4': '4. Click on the save button to save data',
  'p.doctable.document_no.get': 'Get',
  'p.doctable.dashboard.byday': 'By Day',
  'p.doctable.dashboard.bymonthly': 'By Month',
  'p.accessdetails.pagetitle': 'Visit Detail',
  'p.downloaddetails.pagetitle': 'Download Detail',
  'p.accessdetails.userEmail': 'Visit User',
  'p.accessdetails.loginTime': 'Visit Time',
  'p.downloaddetails.documentName': 'Download Document',
  'p.downloaddetails.actionUser': 'Download User',
  'p.downloaddetails.actionDate': 'Download Time',
  'p.detail.back': 'Are you sure to exit the current page?',
  'p.dashboard.user_sqty': 'Users Qty:',
  'p.dashboard.total_document_sqty': 'Total Documents Qty:',
  'p.dashboard.visit_qty': 'Visit Qty',
  'p.dashboard.download_qty': 'Download Qty',
  'p.dashboard.top_ten_favorite_documents': 'Top 10 favorite documents',
  'p.dashboard.top_ten_hot_documents': 'Top 10 hot documents',
  'p.dashboard.new_update_documents_by_month': 'New/update documents by month',
  'p.dashboard.new': 'New',
  'p.dashboard.update': 'Update',
  'p.dashboard.reportformsanalysis.title': 'Document Dashboard Analysis',
  'p.dashboard.systemvisitsanalysis.title': 'System Visit Analysis',
  'p.docdetail.removeType': 'Delete Type',
  'p.docdetail.removeType.one': 'Released Document',
  'p.docdetail.removeType.two': 'Drafted',
  'p.docdetail.leavingpage': 'The changes will be lost, are you sure to leave?',
  'p.docdetail.leavingpage.title': 'Leave Site',
  'p.docdetail.requestrecord': 'Resquest History',
  'p.doctable.type': 'Type',
  'p.doctable.type.new': 'New',
  'p.doctable.type.update': 'Update',
  'p.doctable.leavemessagetime': 'Comments Time',

  'Rule.Vision.title': 'Vision',
  'Rule.Vision.content':
    'Create an efficient, intelligent and green turning world',
  'Rule.Mission.title': 'Mission',
  'Rule.Mission.content1':
    'Be the regional market leader with superior customer experiences',
  'Rule.Mission.content2':
    'Build a benchmark plant in digital operations and energy efficiency',
  'Rule.Mission.content3':
    'Create a learning organization full of care, diversity and inclusiveness',
  'Rule.quality_policy.title': 'Quality Policy',
  'Rule.quality_policy.content1':
    'To ensure that we meet our responsibilities and obligations to our customers, our people, our partners, our suppliers and shareholders, we are committed to the following Quality Objectives:',
  'Rule.quality_policy.content2':
    '-Deliver on-time & on-quality products, systems and services that meet or exceed our customer‘s expectations.',
  'Rule.quality_policy.content3':
    '-Identify and understand our customer’s expectations, measure customer perceptions, and implement improvements to increase customer satisfaction.',
  'Rule.quality_policy.content4':
    '-Enable and engage all employees in a relentless drive to improve operational performance along the value chain from suppliers to customers.',
  'Rule.quality_policy.content5':
    '-Increase the motivation and skills of our people to add value to our customers and our businesses, through continual training and development.',
  'Rule.quality_policy.content6':
    '-Leverage our partners & suppliers to improve our products and our businesses from product design through production, installation and operation.',
  'Rule.quality_policy.content7':
    '-Embed social responsibility & company ethics policies in our business practices.',
  'Rule.quality_policy.content8':
    '-Continually improve environmental, health and safety performance through all products, operations, systems and services.',
};
export default messages;
