const messages = {
  'p.login.error': '登录失败，请重新登陆',
  'p.login.welcome': '大电机与发电机质量管理系统',
  'p.login.cboluser': 'cBOL用户',
  'p.login.spuser': '服务商',
  'p.linkregister': '没有账号，注册一个？',
  'p.linkforgotpwd': '忘记密码？',
  'p.login.or': '或',
  'p.errorpage.403': '访问受限',
  'p.errorpage.404': '页面不存在',
  'p.errorpage.home': '返回首页',
  'p.table.action': '操作',
  'p.table.lastupdatedby': '更新人',
  'p.table.lastupdatedtime': '更新时间',
  'p.table.remark': '备注说明',
  'p.document.document_sn': '文档编号',
  'p.document.version_code': '版本号',
  'p.documenttag.tagcode': '标签编码',
  'p.documenttag.tagname': '标签名称',
  'p.documenttag.tagtype': '标签类型',
  'p.documenttag.relatedtagcode': '关联标签',
  'p.documenttag.sequence': '顺序',
  'p.documenttag.nodeid': '树结构',
  'p.documenttag.rules': '规则',
  'p.documenttag.tagtype.0': '自定义',
  'p.documenttag.tagtype.1': '文本',
  'p.documenttag.tagtype.2': '数字',
  'p.documenttag.tagtype.3': '日期',
  'p.documenttag.tagtype.4': '下拉列表（单选）',
  'p.documenttag.tagtype.5': '下拉列表（多选）',
  'p.documenttag.tagtype.6': '树（单选）',
  'p.documenttag.tagtype.7': '树（多选）',
  'p.documenttag.getlisterror': '获取标签列表失败',

  'p.treenode.parentname': '节点位置',
  'p.treenode.nodecode': '节点编码',
  'p.treenode.name.en-US': '英文名称',
  'p.treenode.name.zh-CN': '中文名称',
  'p.treenode.dataclassification': '数据级别',
  'p.treenode.isDefault': '默认值',
  'p.treenode.sequenceno': '排序',
  'p.treenode.remark': '备注',

  'p.docdetail.pagetitle': '查看详情',
  'p.docdetail.docinfo': '文档信息',
  'p.docdetail.sn': '文档编号',
  'p.docdetail.title': '文档标题',
  'p.docdetail.version': '版本号',
  'p.docdetail.dataclassification': '数据级别',
  'p.docdetail.visitor': '访问人员',
  'p.docdetail.file': '文档',
  'p.docdetail.attachments': '关联附件',
  'p.docdetail.editfile': '升级文档',
  'p.docdetail.edittag': '编辑标签',
  'p.docdetail.documentleaveamessage': '文档留言',
  'p.docdetail.status': '状态',
  'p.docdetail.document_structure_tree': '文档结构树',
  'p.docdetail.select_document_structure_tree': '选择文档结构树',
  'p.docdetail.notify_personnel': '通知人员',
  'p.docdetail.notify_department': '通知部门',
  'p.docdetail.notify_personnel.title': '选择通知人员',
  'p.docdetail.department': '部门',
  'p.docdetail.user': '用户',
  'p.docdetail.type': '文档类型',
  'p.docdetail.author': '作者',
  'p.docdetail.standard': '标准',
  'p.docdetail.structure': '文档结构',
  'p.docdetail.mainfile': '主体文件',
  'p.docdetail.key.word': '关键字',
  'p.docdetail.annex': '附件',
  'p.docdetail.limitaccesslevel': '节点访问等级不同，请确认后进行选择',

  'p.document.createby': '创建人',
  'p.document.reviewby': '审核人',
  'p.document.approvedby': '批准人',

  'p.approvallog.action': '由{user}{action}',
  'p.approvallog.submit': '提交',
  'p.approvallog.approve': '通过',
  'p.approvallog.reject': '拒绝',
  'p.approvallog.removeapprover': '移除',
  'p.approvallog.addapprover': '添加',
  'p.approvallog.pending': '审批中',
  'p.approvallog.unapproved': '待审批',

  'p.doc.moveto': '移动至',
  'p.doc.addto': '添加至',
  'p.doc.viewhistory': '查看历史版本',
  'p.doc.history': '历史版本',
  'p.doc.approvers': '审批人信息',
  'p.doc.approver.review': '审核人员',
  'p.doc.approver.countersign': '会签人员',
  'p.doc.approver.release': '批准人',
  'p.doc.approvalhistory': '审批记录',
  'p.doc.approvalcomment': '审批意见',
  'p.doc.downloadaspdf': '下载为Pdf',
  'p.doc.document_no': '文档编号',
  'p.doctable.shareby': '分享人',
  'p.doctable.sharewith': '分享给',
  'p.doctable.deletedocument': '删除文档',
  'p.doctable.reason': '原因',
  'p.doctable.emailnotifypersonnel': '通知人员',

  'p.updateapprover.listpage.title': '调整审批人',
  'p.updateapprover.detailpage.title': '修改审批人',

  'p.document.create.basicinformation': '文档基本信息',
  'p.common.document': '主体文档',
  'p.common.relateddocument': '附件',
  'p.common.action': '操作',
  'p.common.updated': '已更新',
  'p.doctable.status': '状态',
  'p.doctable.status.draft': '起草中',
  'p.doctable.status.pendingreview': '待审核',
  'p.doctable.pendingratify': '待批准',
  'p.doctable.status.pendingcountersign': '待会签',
  'p.doctable.status.pendingrelease': '待发布',
  'p.doctable.status.approved': '已发布',
  'p.doctable.status.rejected': '已拒绝',
  'p.doctable.pendingapproval': '待审批',
  'p.doctable.status.approvedby1': '审批人1',
  'p.doctable.status.approvedby2': '审批人2',
  'p.doctable.status.approvedby3': '审批人3',
  'p.doctable.status.ratifyby1': '批准人1',
  'p.doctable.status.ratifyby2': '批准人2',
  'p.doctable.status.ratifyby3': '批准人3',
  'p.doctable.approved': '已审批',
  'p.doctable.department': '部门',
  'p.doctable.isostandard': 'ISO标准',
  'p.doctable.subscriptionstatus': '订阅状况',
  'p.doctable.applicantname': '发起人',
  'p.doctable.publishtime': '发布时间',
  'p.doctable.publishstarttime': '发布开始时间',
  'p.doctable.publishendtime': '发布结束时间',
  'p.doctable.applicationTime': '发起时间',
  'p.doctable.applicationstarttime': '发起开始时间',
  'p.doctable.applicationendtime': '发起结束时间',
  'p.doclist.allversion.pagetitle': '文档发起列表',
  'p.doctable.createdtime': '创建时间',
  'p.doctable.document_no': '文档编号',
  'p.doctable.document_name': '中文名称/英文名称',
  'p.doctable.document_condition': '文档编号或中英文名称',
  'p.doctable.fulltextsearch': '全文搜索',
  'p.doctable.waitingprocessing': '待处理',
  'p.doctable.Processed': '已处理',

  'p.approval.pendingdoc': '待审批文档',
  'p.approval.pendingclassification': '待审批数据级别',
  'p.approval.approveddoc': '已审批文档',
  'p.approval.approvedclassification': '已审批数据级别',
  'p.approval.docpagetitle': '审批文档',
  'p.approval.classificationtitle': '审批数据级别变更',
  'p.approval.pending': '待审批',
  'p.approval.approved': '已审批',
  'p.approval.rejected': '已拒绝',
  'p.publish.docpagetitle': '确认发布文档',

  'p.subscription.subscription': '已订阅',
  'p.subscription.unsubscription': '未订阅',

  'p.emailtemplate.classify': '模板分类',
  'p.emailtemplate.name': '模板名称',
  'p.emailtemplate.code': '模板编码',
  'p.emailtemplate.subjecttemplate': '邮件标题',
  'p.emailtemplate.bodytemplate': '邮件内容',
  'p.emailtemplate.createtitle': '新建邮件模板',
  'p.emailtemplate.edittitle': '编辑邮件模板',

  'p.MessageList.name': '留言列表',
  'p.MessageList.givemeleaveamessage': '给我留言',
  'p.MessageList.message': '留言内容',
  'p.MessageList.leaveMessageUser': '留言人',
  'p.doctable.favorite': '收藏夹',
  'p.doctable.favoritecategory': '收藏分类',
  'p.doctable.favoritecategoryname': '收藏夹名称',
  'p.doctable.view': '查看',
  'p.doctable.designatedpersonnel': '指定人员',
  'p.doctable.designateddepartment': '指定部门',
  'p.doctable.sharetime': '分享时间',
  'p.doctable.sharestarttime': '分享开始时间',
  'p.doctable.shareendtime': '分享结束时间',
  'p.doctable.systemfavorites': '系统收藏夹',
  'p.doctable.removedremark': '删除原因',

  'p.announcement.title': '标题',
  'p.announcement.content': '内容',
  'p.announcement.detail.content': '公告内容',
  'p.announcement.iseffect': '是否生效',
  'p.announcement.iseffect.yes': '是',
  'p.announcement.iseffect.no': '否',
  'p.doctable.approver': '批准人',
  'p.doctable.reviewer': '审核人',
  'p.doctable.reviewerorapprover': '审核人/批准人',
  'p.doctable.qa': 'QA',
  'p.doctable.notset': '未设置',
  'p.doctable.loading': '加载中',
  'p.doctable.edit.range': '正在编辑[{value}]号区域',
  'p.doctable.range.remark': '操作说明：',
  'p.doctable.range.remark1': '1、点击上传图片按钮上传地图图片；',
  'p.doctable.range.remark2': '2、在图片区域按住鼠标左键拖动画区域；',
  'p.doctable.range.remark3': '3、在已画区域鼠标双击可设置区域条件',
  'p.doctable.range.remark4': '4、点击保存按钮保存设置。',
  'p.doctable.document_no.get': '获取',
  'p.doctable.dashboard.byday': '按天',
  'p.doctable.dashboard.bymonthly': '按月',

  'p.accessdetails.pagetitle': '访问详情',
  'p.downloaddetails.pagetitle': '下载详情',
  'p.accessdetails.userEmail': '访问人员',
  'p.accessdetails.loginTime': '访问时间',
  'p.downloaddetails.documentName': '下载文档',
  'p.downloaddetails.actionUser': '下载人员',
  'p.downloaddetails.actionDate': '下载时间',
  'p.detail.back': '确定是否退出当前页面吗？',
  'p.dashboard.user_sqty': '用户数:',
  'p.dashboard.total_document_sqty': '总文档数:',
  'p.dashboard.visit_qty': '访问量',
  'p.dashboard.download_qty': '下载量',
  'p.dashboard.top_ten_favorite_documents': 'Top 10 收藏量文档',
  'p.dashboard.top_ten_hot_documents': 'Top 10  访问文档',
  'p.dashboard.new_update_documents_by_month': '按月新建/更新文档数',
  'p.dashboard.new': '新建',
  'p.dashboard.update': '更新',
  'p.dashboard.title': '更新',
  'p.dashboard.reportformsanalysis.title': '文档报表分析',
  'p.dashboard.systemvisitsanalysis.title': '系统访问量分析',

  'p.docdetail.removeType': '删除类别',
  'p.docdetail.removeType.one': '文档',
  'p.docdetail.removeType.two': '草稿',
  'p.docdetail.leavingpage':
    '离开当前页后，所有未保存的数据将会丢失，确认退出吗?',
  'p.docdetail.leavingpage.title': '离开页面',
  'p.docdetail.requestrecord': '申请记录',
  'p.doctable.type': '类型',
  'p.doctable.type.new': '新增',
  'p.doctable.type.update': '更新',
  'p.doctable.leavemessagetime': '留言时间',

  'Rule.Vision.title': '愿景',
  'Rule.Vision.content': '创造高效，智慧，绿色运转的世界',
  'Rule.Mission.title': '使命',
  'Rule.Mission.content1': '创建卓越客户体验，成为亚洲市场领导者',
  'Rule.Mission.content2': '建立数字化运营和节能高效的标杆工厂',
  'Rule.Mission.content3': '创建一个充满关怀、多元化和包容性的学习型组织',
  'Rule.quality_policy.title': '质量方针',
  'Rule.quality_policy.content1':
    '为了确保实现我们对客户、员工、合作伙伴、供应商和股东的责任与义务，我们承诺以下质量目标：',
  'Rule.quality_policy.content2':
    '-按时交付能够满足或超出客户期望的合格成品、系统与服务。',
  'Rule.quality_policy.content3':
    '-明确并理解客户的期望，了解客户的想法，并采取措施提高客户满意度。',
  'Rule.quality_policy.content4':
    '-积极努力，保证并促进公司各级员工不断改善从供应商到客户的整个价值链的运营绩效。',
  'Rule.quality_policy.content5':
    '-通过不断的培训和发展，提高员工的积极性与技能，为客户和自身业务创造更多价值。',
  'Rule.quality_policy.content6':
    '-充分利用合作伙伴与供应商的优势，从产品设计、生成、安装到运行，全面改善我们的产品和业务。',
  'Rule.quality_policy.content7':
    '-在日常工作中切实承担社会责任，遵循公司的商业道德准则。',
  'Rule.quality_policy.content8':
    '-在所有的产品、运营、系统和服务方面持续改善我们的环境、健康与安全绩效。',
};
export default messages;
