import React, { useState, useEffect } from 'react';
import {
  Menu,
  Divider,
  Layout,
  notification,
  Dropdown,
  Row,
  Button,
  // Icon,
} from 'antd';
import {
  UserOutlined,
  ExportOutlined,
  createFromIconfontCN,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectTopMenus,
  selectMenus,
  selectUserType,
  selectUserEmail,
  selectName,
  signOut,
} from '../../features/auth/authSlice';
import { useHistory, Link } from 'react-router-dom';
import './LayoutDefault.less';
import * as LoginService from '@/api/login';
import {
  HomeOutlined,
  SearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import eventBus from '@/utils/eventBus';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError } from '@/utils/handleError';
import LanguageSetting from '../language/LanguageSetting';
import Announcement from '../announcement/Announcement';

// import CreateDocuments from '@/assets/icons/CreateDocuments.png';
import DocumentMap from '@/assets/icons/DocumentMap.png';
import DocumentStructureTree from '@/assets/icons/DocumentStructureTree.png';
import DocumentRetrieval from '@/assets/icons/DocumentRetrieval.png';
import documentdashboard from '@/assets/icons/documentdashboard.png';
import getsupport from '@/assets/icons/getsupport.png';
import myrelated from '@/assets/icons/myrelated.png';
import systemmanagement from '@/assets/icons/systemmanagement.png';

import {
  CreateDocumentsIcon,
  DocumentRetrievalIcon,
  DocumentStructureTreeIcon,
  DocumentdashboardIcon,
  GetsupportIcon,
  MyrelatedIcon,
  SystemmanagementIcon,
  DocumentMapIcon,
  CollectionIcon,
} from '@/utils/customIcon.js';

function LayoutDefault({ noWrapper, noFooter, ...props }) {
  let year = new Date().getFullYear();
  const [selectTopMenuKey, setSelectedTopMenuKey] = useState([]);
  const [selectedMenuOpenKey, setSelectedMenuOpenKey] = useState([]);
  const { Header, Content, Footer, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  //const [screenSize, setScreenSize] = useState({ width: window.innerWidth });
  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [divWidth, setDivWidth] = useState({ width: 1191, height: 800 });
  const history = useHistory();
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);
  useEffect(() => {
    setTimeout(function () {
      let width = screenSize.width;
      let height = screenSize.height;
      // if (width > 1396) {
      //   width = 1396;
      // }
      width = width - 205;
      height = 1200;
      setDivWidth({
        ...divWidth,
        width: width,
        height: height,
      });
    }, 100);
  }, [screenSize]);
  function handleResize() {
    setScreenSize({ width: window.innerWidth });
  }
  useEffect(() => {
    let selects = [];
    let opens = [
      'sharewithme',
      'mycreated',
      'mytask',
      'myshare',
      'myfavorites',
    ];
    let systemmanagements = [
      'processmanagement',
      'masterdatamanagement',
      'recyclebin',
      'announcementmanagement',
      'usermanagement',
      'tagmanagement',
      'emailtemplatesetting',
      'workflowmanagement',
    ];
    let MenusData = {
      home: 'home',
      map: 'documentmap',
      tree: 'documenttree',
      create: 'documentcreate',
      myrelated: 'myrelated',
      query: 'documentquery',
      systemmanagement: 'systemmanagement',
      getsupport: 'getsupport',
    };
    function GetTopMenuCode(url) {
      switch (url) {
        case '/home':
          //首页
          localStorage.setItem('topMenuCode', 'home');
          break;
        case '/documentreport/mycreated':
          //我创建的
          localStorage.setItem('topMenuCode', 'mycreated');
          break;
        case '/document/approval':
        case '/documentreport/mytask':
          //我的任务
          localStorage.setItem('topMenuCode', 'mytask');
          break;
        case '/documentreport/sharewithme':
          //我的任务
          localStorage.setItem('topMenuCode', 'sharewithme');
          break;
        case '/documentreport/myfavorites':
          //我的收藏
          localStorage.setItem('topMenuCode', 'myfavorites');
          break;
        case '/document/pending':
          //审批流程管理
          localStorage.setItem('topMenuCode', 'processmanagement');
          break;
        case '/recyclebin':
          //回收站
          localStorage.setItem('topMenuCode', 'recyclebin');
          break;
        case '/announcementmanagement':
          //公告栏
          localStorage.setItem('topMenuCode', 'announcementmanagement');
          break;
        case '/document/mapsetting':
          //文档地图设置
          localStorage.setItem('topMenuCode', 'documentmapsetting');
          break;
        case '/tagmanagement':
          //标签设置
          localStorage.setItem('topMenuCode', 'tagmanagement');
          break;
        case '/emailtemplate':
          //邮件模板
          localStorage.setItem('topMenuCode', 'emailtemplatesetting');
          break;
        case '/document/map':
          //文档地图
          localStorage.setItem('topMenuCode', 'documentmap');
          break;
        case '/document/tree':
          //文档结构
          localStorage.setItem('topMenuCode', 'documenttree');
          break;
        case '/document/create':
          //文档创建
          localStorage.setItem('topMenuCode', 'documentcreate');
          break;
        case '/documentreport/query':
          //文档检索
          localStorage.setItem('topMenuCode', 'documentquery');
          break;
        case '/documentreport/dashboard':
          //文档报表
          localStorage.setItem('topMenuCode', 'documentdashboard');
          break;
        default:
          break;
      }
    }
    let index = window.location.href.lastIndexOf('/#/');
    var _topMenuCode = window.location.href.substring(index + 2);
    if (_topMenuCode) {
      if (_topMenuCode.toLowerCase().trim().includes('/document/approval')) {
        _topMenuCode = '/document/approval';
      }
      GetTopMenuCode(_topMenuCode.toLowerCase().trim());
    }
    var topMenuCode = localStorage.getItem('topMenuCode');
    if (topMenuCode) {
      selects.push(topMenuCode);
      setSelectedTopMenuKey(selects);
      if (opens.includes(topMenuCode)) {
        setSelectedMenuOpenKey(['myrelated']);
      } else if (systemmanagements.includes(topMenuCode)) {
        setSelectedMenuOpenKey(['systemmanagement']);
      } else {
        setSelectedMenuOpenKey([]);
      }
    }
    if (!topMenuCode) {
      eventBus.addListener('selectedTopMenu', function (val) {
        selects.push(val);
        setSelectedTopMenuKey(selects);
        if (opens.includes(topMenuCode)) {
          setSelectedMenuOpenKey(['myrelated']);
        } else if (systemmanagements.includes(topMenuCode)) {
          setSelectedMenuOpenKey(['systemmanagement']);
        } else {
          setSelectedMenuOpenKey([]);
        }
        localStorage.setItem('topMenuCode', val);
      });
    }
  }, []);
  function handleResize() {
    setScreenSize({ height: window.innerHeight, width: window.innerWidth });
  }
  function handleTopMenuClick(e) {
    setSelectedTopMenuKey(e.key);
    localStorage.setItem('topMenuCode', e.key);
  }
  function onOpenMenuChange(e) {
    setSelectedMenuOpenKey(e);
  }
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    // if (collapsed) {
    //   setDivWidth({
    //     ...divWidth,
    //     maxWidth: divWidth.width,
    //   });
    // } else {
    //   setDivWidth({
    //     ...divWidth,
    //     maxWidth: divWidth.width + 150,
    //   });
    // }
  };
  function onJumpHome() {
    localStorage.setItem('topMenuCode', 'home');
    history.push('/home');
  }
  return (
    <Layout
      style={{
        minHeight: '100%',
        //minWidth: '1000px',
        backgroundColor: '#f5f5f5',
        fontFamily: 'ABBVoice',
      }}
      {...props}
    >
      <div className="app-header-wrapper">
        <Row
          className={'abbBar'}
          // style={{
          //   height: '32px',
          //   alignContent: 'center',
          // }}
        >
          <div className="logo" onClick={onJumpHome}></div>
          <div className="header-name">
            <span>大电机与发电机质量管理系统</span>
            <br></br>
            <span style={{ fontSize: '12px', fontWeight: '1' }}>
              Quality Management System
            </span>
          </div>
          <div
            style={{ position: 'absolute', right: '0px', marginRight: '48px' }}
          >
            <Toolbar />
          </div>
        </Row>
        {/* <Header>
          <div className="header-bar">
            <NavTopMenubar
              selectedKey={selectTopMenuKey}
              handleTopMenuClick={handleTopMenuClick}
            />
          </div>
        </Header> */}
      </div>
      <Layout
        hasSider
        style={{
          marginTop: '5px',
          // flex: 1,
          // flex: 1,
          Height: 'calc(100vh - 100px)',
          // backgroundColor: 'red',
          //maxWidth: '1396px',
          // display: 'flex',
          // justifyContent: 'center',
          // maxWidth: '1200px',
        }}
      >
        <Sider
          // width={200}
          // style={{ display: 'flex' }}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0)',
            paddingBottom: '8px',
            // maxHeight: 'calc(100vh - 105px)',
            // boxShadow: '0 0 5px rgba(0,0,0,.3)',
            // maxHeight: '82vh',
            // overflow: 'auto',
          }}
          collapsedWidth={50}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="app-menu-wrapper">
            <div
              className="app-menu-content"
              style={{
                width: '100%',
                // maxHeight: 'calc(100vh - 105px)',
                overflow: 'auto',
              }}
            >
              <Button
                type="link"
                onClick={toggleCollapsed}
                style={{ marginBottom: '0px' }}
              >
                {collapsed ? (
                  <RightOutlined
                    style={{
                      color: 'black',
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  />
                ) : (
                  <LeftOutlined
                    style={{
                      color: 'black',
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  />
                )}
              </Button>
              <NavLeftMainMenubar
                selectedKey={selectTopMenuKey}
                selectedOpenKey={selectedMenuOpenKey}
                handleTopMenuClick={handleTopMenuClick}
                onOpenMenuChange={onOpenMenuChange}
                inlineCollapsed={collapsed}
              />
            </div>
          </div>
        </Sider>
        <Content
          style={
            {
              //display: 'flex',
            }
          }
        >
          {noWrapper ? (
            props.children
          ) : (
            <div className="app-main-wrapper">
              <div
                className="app-main-content"
                style={{
                  //width: divWidth.width,
                  //maxWidth: divWidth.width + 150,
                  WebkitTransition: 'maxWidth .3s',
                  transition: 'maxWidth .3s',
                  //maxHeight: 'calc(100vh - 105px)',
                  overflow: 'auto',
                }}
              >
                {props.children}
              </div>
            </div>
          )}
        </Content>
      </Layout>
      {noFooter ? null : (
        <Footer
          style={{
            textAlign: 'center',
            fontFamily: 'ABBVoice',
            // marginTop: '5px',
            maxHeight: '40px',
            padding: '10px 0px 10px 0px',
          }}
        >
          &copy; {year} ABB
        </Footer>
      )}
    </Layout>
  );
}

export default LayoutDefault;

/**
 * 头部菜单
 * @param {*} param0
 * @returns
 */
export function NavTopMenubar(props) {
  const menus = useSelector(selectTopMenus);
  const childrenmenus = useSelector(selectMenus);
  const intl = useIntl();

  function handleTopMenuClick(e) {
    props.handleTopMenuClick(e);
  }

  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let navitem = {
        key: item.menuCode,
        label: (
          <Link to={item.url}>
            <span style={{ color: 'black' }}>
              {/* {item.menuCode === 'home' && (
                <HomeOutlined
                  style={{ fontSize: '16px', marginRight: '14px' }}
                />
              )} */}
              {intl.formatMessage({ id: 'menu.' + item.menuCode })}
            </span>
          </Link>
        ),
      };
      let childrens = childrenmenus.filter(x => x.parentID === item.id);
      let childrennavItems = [];
      for (let i = 0; i < childrens.length; i++) {
        let childrennavItem = {
          ...childrens[i],
          key: childrens[i].menuCode,
          label: intl.formatMessage({ id: 'menu.' + childrens[i].menuCode }),
          children: null,
        };
        if (childrennavItem.url.indexOf('http') === -1) {
          childrennavItem.label = (
            <Link to={childrennavItem.url}>
              {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
            </Link>
          );
        } else {
          childrennavItem.label = (
            <a href={childrennavItem.url} target="_top">
              {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
            </a>
          );
        }
        childrennavItems.push(childrennavItem);
      }
      navitem.children = childrennavItems;

      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="topmenus"
      onClick={handleTopMenuClick}
      mode="horizontal"
      items={getNavItems(menus)}
    />
  );
}

function GetIconImg(menuCode) {
  let result = undefined;
  switch (menuCode) {
    case 'documentmap': //文档地图
      return (
        <DocumentMapIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    case 'documenttree': //文档结构树
      return (
        <DocumentStructureTreeIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'documentcreate': //创建文档
      return (
        <CreateDocumentsIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'myrelated': //与我相关
      return (
        <MyrelatedIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    case 'documentquery': //文档搜索
      return (
        <DocumentRetrievalIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'documentdashboard': //文档报表
      return (
        <DocumentdashboardIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'systemmanagement': //系统管理
      return (
        <SystemmanagementIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'getsupport': //帮助
      return (
        <GetsupportIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    default:
      break;
  }
  return <></>;
}
/**
 * 左侧主菜单
 * @param {*} param0
 * @returns
 */
function NavLeftMainMenubar(props) {
  const menus = useSelector(selectTopMenus);
  const childrenmenus = useSelector(selectMenus);
  const history = useHistory();
  const intl = useIntl();
  function handleTopMenuClick(e) {
    props.handleTopMenuClick(e);
  }
  function onOpenMenuChange(e) {
    props.onOpenMenuChange(e);
  }
  function onJump(url, menuCode) {
    console.log(url);
    if (url === 'workflowmanagement') {
      const hrefStr = window.location.href;
      let index = hrefStr.indexOf('/#/');
      const _url = hrefStr.substring(0, index + 1) + url;
      window.open(_url, '_blank');
    } else {
      window.open(url, '_blank');
    }
    return;
  }
  let blankurl = ['getsupport', 'usermanagement', 'workflowmanagement'];
  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let navitem = {
        key: item.menuCode,
        label: (
          <>
            {blankurl.includes(item.menuCode) ? (
              <Link
                onClick={e => {
                  e.preventDefault();
                  onJump(item.url, item.menuCode);
                }}
                title={intl.formatMessage({ id: 'menu.' + item.menuCode })}
              >
                <span style={{ fontFamily: 'ABBVoice' }}>
                  {GetIconImg(item.menuCode)}
                  {intl.formatMessage({ id: 'menu.' + item.menuCode })}
                </span>
              </Link>
            ) : (
              <Link
                to={item.url}
                title={intl.formatMessage({ id: 'menu.' + item.menuCode })}
              >
                <span style={{ fontFamily: 'ABBVoice' }}>
                  {item.menuCode === 'home' && (
                    <HomeOutlined
                      style={{ fontSize: '16px', marginRight: '14px' }}
                    />
                  )}
                  {GetIconImg(item.menuCode)}
                  {intl.formatMessage({ id: 'menu.' + item.menuCode })}
                </span>
              </Link>
            )}
          </>
        ),
      };
      // if (item.menuCode === 'home') {
      //   navitem.icon = <HomeOutlined />;
      // } else if (item.menuCode === 'documentquery') {
      //   navitem.icon = <SearchOutlined />;
      // }
      let childrens = childrenmenus.filter(x => x.parentID === item.id);
      let childrennavItems = [];
      for (let i = 0; i < childrens.length; i++) {
        let childrennavItem = {
          ...childrens[i],
          key: childrens[i].menuCode,
          label: intl.formatMessage({ id: 'menu.' + childrens[i].menuCode }),
          children: null,
          icon: null,
        };
        childrennavItem.label = (
          <>
            {blankurl.includes(childrennavItem.menuCode) ? (
              <Link
                onClick={e => {
                  e.preventDefault();
                  onJump(childrennavItem.url, childrennavItem.menuCode);
                }}
                style={{ fontFamily: 'ABBVoice' }}
                title={intl.formatMessage({
                  id: 'menu.' + childrennavItem.menuCode,
                })}
              >
                {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
              </Link>
            ) : (
              <Link
                to={childrennavItem.url}
                title={intl.formatMessage({
                  id: 'menu.' + childrennavItem.menuCode,
                })}
                style={{ fontFamily: 'ABBVoice' }}
              >
                {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
              </Link>
            )}
          </>
        );
        childrennavItems.push(childrennavItem);
      }
      if (childrennavItems.length > 0) {
        navitem.children = childrennavItems;
      }
      navItems.push(navitem);
    }

    return navItems;
  }
  if (props.selectedOpenKey.length > 0) {
    return (
      <Menu
        width={200}
        // style={{ height: '100%', overflow: 'auto' }}
        // className="topmenus"
        onClick={handleTopMenuClick}
        onOpenChange={onOpenMenuChange}
        mode="inline"
        items={getNavItems(menus)}
        openKeys={props.selectedOpenKey}
        selectedKeys={props.selectedKey}
        inlineCollapsed={true}
      />
    );
  } else {
    return (
      <Menu
        width={200}
        // style={{ height: '100%', overflow: 'auto' }}
        // className="topmenus"
        onClick={handleTopMenuClick}
        onOpenChange={onOpenMenuChange}
        mode="inline"
        items={getNavItems(menus)}
        selectedKeys={props.selectedKey}
        inlineCollapsed={true}
      />
    );
  }
}

/**
 * 左侧菜单
 * @param {*} param0
 * @returns
 */
function NavLeftMenubar(props) {
  debugger;
  const topmenus = useSelector(selectTopMenus);
  const menus = useSelector(selectMenus);
  const [openMenuKey, setOpenedMenuKey] = useState([]);
  const intl = useIntl();
  let selectedTopMenuID =
    topmenus.length > 0
      ? props.selectTopMenuID || topmenus[0].Id
      : props.selectTopMenuID;
  let defaultOpenMenuKey = props.openLeftMenuKey.split(',');

  function handleLeftMenuClick(e) {
    props.handleLeftMenuClick(e);
  }

  function handleSetBadgeCount() {
    props.handleSetBadgeCount();
  }

  function getNavItems(items) {
    debugger;
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.parentID !== selectedTopMenuID) {
        continue;
      }
      let navitem = {
        key: item.menuCode,
        label: intl.formatMessage({ id: 'menu.' + item.menuCode }),
      };
      if (item.children && item.children.length > 0) {
        navitem.children = [];
        for (let j = 0; j < item.children.length; j++) {
          const x = item.children[j];
          let navcitem = {
            key: x.menuCode,
            label: (
              <Link to={x.url === undefined ? '' : x.url}>
                {intl.formatMessage({ id: 'menu.' + x.menuCode })}
                <span id={x.menuCode} className="badgeCount">
                  0
                </span>
              </Link>
            ),
          };
          if (x.children && x.children.length > 0) {
            navcitem.children = [];
            for (let k = 0; k < x.children.length; k++) {
              const y = x.children[k];
              let navccitem = {
                key: y.menuCode,
                label: (
                  <Link to={y.url === undefined ? '' : y.url}>
                    {intl.formatMessage({ id: 'menu.' + y.menuCode })}
                    <span id={y.menuCode} className="badgeCount">
                      0
                    </span>
                  </Link>
                ),
              };
              navcitem.children.push(navccitem);
            }
          }
          navitem.children.push(navcitem);
        }
      } else {
        navitem.label = (
          <Link to={item.url}>
            {intl.formatMessage({ id: 'menu.' + item.menuCode })}
            <span id={item.menuCode} className="badgeCount">
              0
            </span>
          </Link>
        );
      }
      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="leftmenus"
      mode="inline"
      openKeys={openMenuKey.length > 0 ? openMenuKey : defaultOpenMenuKey}
      selectedKeys={[props.selectedKey]}
      onClick={handleLeftMenuClick}
      onOpenChange={openKeys => {
        setOpenedMenuKey(openKeys);
        handleSetBadgeCount();
      }}
      items={getNavItems(menus)}
    />
  );
}

/**
 * 右侧操作按钮
 * @param {*} props
 * @returns
 */
export function Toolbar(props) {
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const userEmail = useSelector(selectUserEmail);
  const name = useSelector(selectName);
  const userItems = [
    {
      label: '修改用户信息',
      key: 'edituser',
    },
  ];
  const handleUserMenuClick = key => {
    if (key === 'edituser') {
      var url = window.ENV_CFG.edituser_link;
      url = url.replace('{0}', userEmail);
      window.open(url, '_blank');
    }
  };

  const handleLogout = () => {
    dispatch(signOut());
    if (userType === 0) {
      window.location.href = window.ENV_CFG.signout;
    } else {
      LoginService.logout()
        .then(res => {
          if (res.tag === 1) {
            window.location.reload();
          } else {
            notification.error({
              message: res.message,
              placement: 'top',
              duration: 4,
            });
          }
        })
        .catch(e => {
          handleError(e);
        });
    }
  };

  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <Announcement className="lang" />
      </div> */}
      <div className="control-item">
        <LanguageSetting className="lang" />
      </div>
      {name && name.length > 0 && (
        <React.Fragment>
          {/* <div className="control-item">
            <UserOutlined /> {name}
          </div> */}
          {userType === 2 && (
            <div className="control-item">
              <Dropdown
                overlay={
                  <Menu
                    items={userItems}
                    onClick={({ key, domEvent }) => {
                      domEvent.stopPropagation();
                      handleUserMenuClick(key);
                    }}
                  ></Menu>
                }
              >
                <div>
                  <UserOutlined style={{ fontSize: '16px' }} /> {name}
                </div>
              </Dropdown>
            </div>
          )}
          {userType !== 2 && (
            <div className="control-item">
              <UserOutlined style={{ fontSize: '16px' }} /> {name}
            </div>
          )}
          <Divider type="vertical" />
          <div className="control-item" onClick={handleLogout}>
            <ExportOutlined style={{ fontSize: '16px' }} />
            <FormattedMessage id="layout.signout" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
